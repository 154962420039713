import Nav from './components/Nav'
import Footer from './components/Footer'
import './App.css'
import { Outlet, Route, Routes } from 'react-router-dom'
import MainPage from './pages/MainPage'
import SiteTerm from './pages/SiteTerm'
import SitePrivacy from './pages/SitePrivacy'
import SiteCollection from './pages/SiteCollection'

function Layout() {
  return (
    <div>
      <Nav />
      <Outlet />
      <Footer />
    </div>
  )
}

function App() {
  return (
    <div className='app'>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index path='main' element={<MainPage/> } />
          <Route path='siteterm' element={<SiteTerm/> } />
          <Route path='siteprivacy' element={<SitePrivacy/> } />
          <Route path='sitecollection' element={<SiteCollection/> } />
        </Route>
      </Routes>
    </div>
  ); 
}

export default App;
