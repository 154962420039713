import React from 'react'
import './Branch.css'

export default function Branch() {

  return (
    <section className='branch' style={{backgroundImage:'url(./images/branch_bg.jpg)'}} >
      <div className='inner'>
        <div className='title'>
          <h2>
            <span className='first-text'>4차혁명 시대 리더를 위한 </span>
            <span className='second-text'>
              <span className='strong'>코딩</span>·<span className='strong'>수학</span>·<span className='strong'>과학</span>·<span className='strong'>영재</span> 브랜치
            </span>
          </h2>
        </div>
        <ul className='content'>
          <li className='subject coding'>
            <div className='branch-tree'>
              <img className='tree' src='./images/branch.png' alt='오일러브랜치' />
            </div>
            <div className='front'>
              <img src="./images/subject_cd1.png" alt='코딩브랜치' />
            </div>
            <div className='back'>
              <img src="./images/subject_cd2.png" alt='코딩브랜치' />
            </div>
          </li>
          <li className='subject math'>
            <div className='branch-tree'>
              <img className='tree' src='./images/branch.png' alt='오일러브랜치' />
            </div>
            <div className='front'>
              <img src="./images/subject_cd3.png" alt='수학브랜치' />
            </div>
            <div className='back'>
              <img src="./images/subject_cd4.png" alt='수학브랜치' />
            </div>
          </li>
          <li className='subject science'>
            <div className='branch-tree'>
              <img className='tree' src='./images/branch.png' alt='오일러브랜치' />
            </div>
            <div className='front'>
              <img src="./images/subject_cd5.png" alt='과학브랜치' />
            </div>
            <div className='back'>
              <img src="./images/subject_cd6.png" alt='과학브랜치' />
            </div>
          </li>
          <li className='subject genius'>
            <div className='branch-tree'>
              <img className='tree' src='./images/branch.png' alt='오일러브랜치' />
            </div>
            <div className='front'>
              <img src="./images/subject_cd7.png" alt='영재브랜치' />
            </div>
            <div className='back'>
              <img src="./images/subject_cd8.png" alt='코딩브랜치' />
            </div>
          </li>
        </ul>
      </div>
    </section>
  )
}