import './Awards.css'

export default function Awards() {
  
  return (
    <>
    <section className='awards'>
      <div className='inner'>
        <div className="history">
          <div className='history_title'>
            <a href="https://blog.naver.com/PostList.naver?blogId=euleredu&from=postList&categoryNo=65" target="_blank">
              <h2>수상실적</h2>
              <span className="material-symbols-outlined">more_vert</span>
            </a>
          </div>
          <ul className='history_content'>
            <li><span>한국정보올림피아드(KOI)</span><span>대상 16, 금상 135, 은상 296, 동상 336, 장려 148</span></li>
            <li><span>국제정보올림피아드(IOI)</span><span>금메달 3, 은메달 3, 동메달 1</span></li>
            <li><span>아시아태평양정보올림피아드(APIO)</span><span>금메달 3, 은메달 3, 동메달 1</span></li>
            <li><span>넥슨청소년프로그램챌린지(NYPC)</span><span>대상 1, 금상 1, 동상 4, 특별상 4</span></li>
            <li><span>이화여대 전국여고생 프로그래밍</span><span>최우수 1, 장려 3</span></li>
            <li><span>서강대 고등학생 알고리즘 대회</span><span>대상 1, 금상 1, 은상 1, 동상 1</span></li>
            <li><span>국민대 고등학생 알고리즘 대회</span><span>은상 1, 동상 1, 장려 2</span></li>
            <li><span>경희대 고등학생 알고리즘 대회</span><span>금상 1, 은상 1, 동상 1</span></li>
            <li><span>한국정보과학경시대회</span><span>대상 1, 금상 3, 은상 6, 동상 10, 장려 7</span></li>
            <li><span>ICT-AWARD KOREA 알고리즘</span><span>대상 5, 금상 2, 은상 1, 동상 9, 장려 7</span></li>
            <li><span>한국수학올림피아드(KMO)</span><span>은상 2, 동상 2, 장려 2</span></li>
            <li><span>한국중학생물리대회(KPhE)</span><span>금상 1, 은상 2, 동상 3, 장려 2</span></li>
            <li><span>한국중학생화학대회(KMChC)</span><span>금상 2, 은상 7, 동상 3, 장려 10</span></li>
            <li><span>한국주니어수학올림피아드(KJMO)</span><span>동상 2, 장려 2</span></li>
          </ul>
        </div>

        <div className="grade_pc">
          <img src="./images/grade04_pc.jpg" alt="정보올림피아드 만점" />
        </div>
        <div className="grade_pc">
          <img src="./images/grade03_pc.jpg" alt="정보올림피아드 1차대회" />
        </div>
        <div className="grade_pc">
          <img src="./images/grade01_pc.jpg" alt="정보올림피아드 1차대회" />
        </div>
        <div className="grade_pc">
          <img src="./images/grade02_pc.jpg" alt="수학올림피아드 1차대회" />
        </div>

        <div className="grade_mo">
          <img src="./images/grade04_mo.jpg" alt="정보올림피아드 만점" />
        </div>
        <div className="grade_mo">
          <img src="./images/grade03_mo.jpg" alt="정보올림피아드 1차대회" />
        </div>
        <div className="grade_mo">
          <img src="./images/grade01_mo.jpg" alt="정보올림피아드 1차대회" />
        </div>
        <div className="grade_mo">
          <img src="./images/grade02_mo.jpg" alt="수학올림피아드 1차대회" />
        </div>
      </div>
    </section>
    
    { /*
    <section className='awards-banner' style={{backgroundImage:'url(./images/award_bg.jpg)'}} >
      <div className='inner'>
        <div className='awards-login'>
          <img className='we-see-img' src='/images/award_text.png' alt='더 큰 꿈을 보다' />
          <div className="btn-group">
            <div className="btn btn--reverse child">학생 로그인</div>
            <div className="btn parents">학부모 로그인</div>
            <div className="btn staff">STAFF 로그인</div>
          </div>
        </div>
        <div className='float-person1' ref={ floatPersonRef1 }>
          <img src='/images/award_person1.png' alt='오일러 학생1' />
        </div>
        <div className='float-person2' ref={ floatPersonRef2 }>
          <img src='/images/award_person2.png' alt='오일러 학생2' />
        </div>
      </div>
    </section> */ }
    </>
  )
}