import { useEffect } from 'react'
import { useLocation } from "react-router-dom"
import './SiteTerm.css'

const SiteCollection = () => {
  const { pathname } = useLocation()

  useEffect(()=>{
    window.scrollTo(0, 0)
  },[pathname])
  
  return (
    <section className='siteterm'>
      <div className="inner">
        <div className='header'>
          <img src='./images/siteterm_logo.png' alt='Logo' />
          <h1><strong>개인정보 무단수집 거부</strong></h1>
        </div>
        <dl className='term'>
        <dt><p className='term-title'>1.개인정보 무단수집 거부</p></dt>
          <dd><p className='term-text'>본 웹사이트에 게시된 개인 정보가 프로그램이나 그 밖의 기술적 장치를 이용하여 무단으로 수집되는 것을 거부하며, 이를 위반시 정보통신망법에 의해 형사처벌됨을 유의하시기 바랍니다</p></dd>
        </dl>
      </div>
    </section>
  )
}

export default SiteCollection