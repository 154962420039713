import { useEffect } from 'react'
import { useLocation } from "react-router-dom"
import './SiteTerm.css'

const SitePrivacy = () => {
  const { pathname } = useLocation()

  useEffect(()=>{
    window.scrollTo(0, 0)
  },[pathname])
  
  return (
    <section className='siteterm'>
      <div className="inner">
        <div className='header'>
          <img src='./images/siteterm_logo.png' alt='Logo' />
          <h1><strong>개인정보처리방침</strong></h1>
        </div>
        <dl className='term'>
          <dt><p className='term-title'>1. 개인정보의 수집목적 및 이용</p></dt>
          <dd><p className='term-text'>① 오일러(주)(이하 “회사”라 합니다)는 정보주체의 자유와 권리 보호를 위해 「개인정보보호법」 및 관계 법령이 정한 바를 준수하여, 적법하게 개인정보를 처리하고 안전하게 관리하고 있습니다.</p></dd>
          <dd><p className='term-text'>② 오일러(주)가 ｢개인정보 보호법｣ 제30조에 따라 회원님의 개인 정보를 수집하는 목적은 오일러교육 사이트를 통해서 회원님들께 최적의 맞춤 서비스를 제공해 드리기 위해서입니다. 오일러(주)에서는 각종의 교육 정보를 제공해 드리고 있으며 이러한 서비스 이용간 회원님께서 제공해주신 개인정보를 바탕으로 회원님께 보다 유용한 정보를 원할하게 제공하는 것이 가능하게 됩니다.</p></dd>

          <dt><p className='term-title'>2. 개인정보의 수집에 대한 동의</p></dt>
          <dd><p className='term-text'>① 회사는 개인정보처리방침의 내용에 대하여 『동의』할 수 있는 절차를 마련하여, 회원이 『동의』버튼을 클릭하면 개인정보 수집에 대해 동의한 것으로 간주합니다.</p></dd>
          <dd><p className='term-text'>② 단, 학부모가 자녀를 추가로 가입하는 경우 학부모가 『동의』버튼을 선택하면 자녀의 개인정보 수집에 대해 동의한 것으로 간주합니다.</p></dd>

          <dt><p className='term-title'>3. 개인정보의 수집 방법 및 항목</p></dt>
          <dd><p className='term-text'>① 개인정보 수집 방법</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 홈페이지를 통한 회원가입, 입학시험 예약, 학원등록, 상담게시판, 행사 참가</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 회원 또는 제휴사로부터의 제공</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 생성 정보 수집 툴을 통한 수집</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 학원 시설(강의실 등)에 설치된 CCTV를 통한 정보 수집</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 학원에서 학생 관리 및 환불 처리를 통한 정보 수집</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 영상정보처리기기(네트워크 카메라 등)를 통한 영상(초상 포함) 및 음성 정보 수집</p></dd>
          <dd><p className='term-text'>② 각각의 개인정보 수집항목은 다음과 같습니다.</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 학부모 온라인 회원 가입시 : 이메일(학부모), 비밀번호, 휴대폰번호(학부모)</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 고객상담 및 테스트 : 학생이름, 학교, 학년, 휴대폰번호(학부모), 상담 내용 및 문의사항</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 행사 참가 시 : 학생이름, 학교, 학년, 휴대폰번호(학부모), 상담 내용 및 문의사항</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 입학 및 수강 시 : 학생이름, 생년월일, 학교, 학년, 휴대폰번호(학부모, 학생), 자택번호, 사진(학생), 주소</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 수강료 결제 시 : 학생이름, 휴대폰번호(학부모, 학생)</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 은행을 통한 환불 시 : 필수 환불 계좌번호</p></dd>

          <dt><p className='term-title'>4. 개인 정보의 처리 목적</p></dt>
          <dd><p className='term-text'>회사는 다음의 목적을 위하여 최소한의 개인정보를 수집하여 처리합니다.</p></dd>
          <dd><p className='term-text'>① 회원 가입 : 회원제 서비스 이용에 따른 본인확인, 개인 식별, 불량 회원의 부정 이용 방지와 비인가 사용 방지, 가입 의사 확인, 연령 확인, 학사기록 관리, 만14세 미만 아동 개인정보 수집 시 법정 대리인 동의여부 확인, 불만 처리 등 민원처리, 고지사항 전달</p></dd>
          <dd><p className='term-text'>② 방문 상담, 테스트 및 수강 신청 : 강의등록 및 서비스 제공, 고지사항 전달, 학사기록 관리, 이벤트 참여, 민원처리, 환불 접수, 수준 테스트 및 과정 맞춤형 컨설팅, 고객 상담 등</p></dd>
          <dd><p className='term-text'>③ 서비스 제공에 관한 계약 이행 및 요금 정산 : 강의 및 콘텐츠 제공, 교육서비스 제공 사실 확인, 수업환경 모니터링, VOD형태의 강의자료 활용, 구매 및 요금 결제, 물품 배송 또는 발송, 미납요금 추심, 입학시험 일정 안내</p></dd>
          <dd><p className='term-text'>④ 기록 보관 : 학원법 시행규칙 제17조에 따른 기록보관</p></dd>
          <dd><p className='term-text'>⑤ 홍보 및 마케팅, 광고 : 수상 및 합격 사실 공개 등을 통한 홍보와 마케팅, 신규 서비스(제품) 개발 및 특화, 이벤트 등 광고성 정보 전달, 접속 빈도 파악, 회원의 서비스 이용에 대한 통계</p></dd>
 
          <dt><p className='term-title'>5. 개인정보의 보유 및 이용기간</p></dt>
          <dd><p className='term-text'>① 회사는 법령에 따른 개인정보 보유‧이용기간 또는 정보주체로부터 개인정보 수집 시에 동의 받은 개인정보 보유‧이용기간 내에서 개인정보를 처리하고 보유합니다.</p></dd>
          <dd><p className='term-text'>② 회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.</p></dd>
          <dd><p className='term-text'>③ 로그온 ID</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 부정사용 방지 및 재가입 금지 : 보존기간 3년</p></dd>
          <dd><p className='term-text'>④ 이름, 자택 전화번호, 자택 주소, 휴대폰 번호, 법정대리인 정보, 결제 기록</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 표시ㆍ광고에 관한 기록 : 6월</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 계약 또는 청약철회 등에 관한 기록 : 5년</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 대금 결제 및 재화 등의 공급에 관한 기록 : 5년</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년</p></dd>
          <dd><p className='term-text'>⑤ 줌수업 또는 Online 수업, CCTV를 통한 영상 기록(생성/수집) 및 음성 정보</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 보존 이유 : 교육서비스 제공 사실 확인 수업환경 모니터링, 수강생 안전 확보, VOD형태의 강의자료 활용</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 보존 기간 : 1년 (단, CCTV를 통한 영상 및 음성 기록의 경우, 촬영 시로부터 90일)</p></dd>
          <dd><p className='term-text'>⑥ 회사는 다음의 사유에 해당하는 경우에는 해당 사유 종료 시까지 보관합니다.</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 관련 법령 위반에 따른 수사·조사 등이 진행 중인 경우에는 해당 수사·조사 종료 시까지</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 홈페이지 이용에 따른 채권·채무관계 잔존 시에는 해당 채권·채무관계 정산 시까지</p></dd>
          
          <dt><p className='term-title'>6. 개인정보의 파기절차 및 파기방법</p></dt>
          <dd><p className='term-text'>① 회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체 없이 해당 개인정보를 파기합니다.</p></dd>
          <dd><p className='term-text'>② 회사는 정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.</p></dd>
          <dd><p className='term-text'>③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.</p></dd>
          <dd><p className='term-text'>④ 파기 절차</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.</p></dd>
          <dd><p className='term-text'>⑤ 파기 방법</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 종이 문서에 기록 저장된 개인정보 : 분쇄기로 분쇄하거나 소각</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 전자적 파일 형태로 기록 저장된 개인정보 : 기록을 재생할 수 없도록 파기</p></dd>
          <dd><p className='term-text'>⑥ 미이용자의 개인정보 파기 등</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 회원가입 후 1년간 서비스 이용 및 로그인하지 않은 이용자의 개인정보는 휴면회원으로 별도 분리 보관하며, 휴면회원 전환 후 4년이 경과된 개인정보는 지체 없이 파기합니다.</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 개인정보의 파기를 원치 않으시는 경우, 기간 만료 전 서비스 로그인을 하시면 됩니다.</p></dd>

          <dt><p className='term-title'>7. 개인정보의 제3자 제공에 관한 사항</p></dt>
          <dd><p className='term-text'>① 회사는 정보주체의 동의, 법률의 특별한 규정 등 「개인정보보호법」제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공하고 그 이외에는 정보주체의 개인정보를 제3자에게 제공하지 않습니다.</p></dd>
          <dd><p className='term-text'>② 회사는 원활한 서비스 제공을 위해 다음의 경우 정보주체의 동의를 얻어 필요 최소한의 범위로만 제공합니다.</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 제공 대상 : 오일러(주)가 운영하는 캠퍼스 가맹점</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 제공하는 개인정보 항목	: 이름, 생년월일, E-mail, 자택주소, 휴대폰 번호, 자택번호, 학교</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 제공 정보의 이용 목적 : 각 가맹점 재원생 학습/운영/첨삭평가, 교재 등 물품의 배송 정보 및 재원여부 확인, 이벤트 진행</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 기간	: 회원 탈퇴 시까지</p></dd>
          <dd><p className='term-text'>③ 회원은 개인정보 제3자 제공에 대한 동의를 거부할 권리가 있으며, 동의를 거부할 경우 해당 서비스 이용이 불가능하거나, 서비스 이용목적에 따른 서비스 제공에 제한이 따를 수 있습니다.</p></dd>

          <dt><p className='term-title'>8. 정보주체와 법정대리인의 권리ㆍ의무 및 그 행사방법에 관한 사항</p></dt>
          <dd><p className='term-text'>① 회원은 회사에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다. 단, 만 14세 미만 아동에 관한 개인정보의 열람 등 요구는 법정대리인이 직접 해야 하며, 만 14세 이상의 미성년자인 정보주체는 정보주체의 개인정보에 관하여 미성년자 본인이 권리를 행사하거나 법정대리인을 통하여 권리를 행사할 수도 있습니다.</p></dd>
          <dd><p className='term-text'>② 권리 행사는 회사에 대해 「개인정보 보호법」 시행령 제41조 제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며, 회사는 이에 대해 지체 없이 조치하겠습니다.</p></dd>
          <dd><p className='term-text'>③ 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수도 있습니다. 이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하시거나, 기타 회사가 요구하는 확인 방법에 따라야 합니다.</p></dd>
          <dd><p className='term-text'>④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한될 수 있습니다.</p></dd>
          <dd><p className='term-text'>⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.</p></dd>
          <dd><p className='term-text'>⑥ 회사는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.</p></dd>
          <dd><p className='term-text'>⑦ 개인정보 조회, 수정을 위해서는 “개인정보 변경”(또는 “회원정보수정” 등)을, 가입 해지(동의 철회)를 위해서는 “회원 탈퇴”를 클릭하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다.</p></dd>

          <dt><p className='term-title'>9. 개인정보를 자동으로 수집하는 장치의 설치ㆍ운영 및 그 거부에 관한 사항</p></dt>
          <dd><p className='term-text'>① 회사는 정보주체에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다. 쿠키란 회사의 웹사이트를 운영하는데 사용되는 서버(http)가 이용자의 브라우저에 보내는 소량의 정보로서, 이용자들의 PC 컴퓨터 내의 하드디스크에 저장되기도 합니다.</p></dd>
          <dd><p className='term-text'>② 쿠키 사용 목적</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.</p></dd>
          <dd><p className='term-text'>③ 쿠키의 설치·운영 및 거부</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 웹브라우저별 옵션 설정을 통해 쿠키 저장을 거부할 수 있습니다.</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 설정 방법 예(엣지 브라우저 : 설정 &gt; 업데이트 및 보안 / 크롬 브라우저 : 설정 &gt; 개인정보 및 보안 / 사파리 브라우저 : 설정 &gt; 보안)</p></dd>
          <dd><p className='term-text'>④ 단, 쿠키 설치·저장을 거부할 경우 서비스 이용에 어려움이 있을 수 있습니다.</p></dd>

          <dt><p className='term-title'>10. 개인정보의 안전성 확보조치</p></dt>
          <dd><p className='term-text'>① 회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 관리적 조치 : 내부관리계획 수립․시행, 정기적 직원 교육 등</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 고유식별정보 등의 암호화, 보안프로그램 설치(백신 프로그램 포함) 및 갱신</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 물리적 조치 : 외부로부터 접근이 통제된 구역에 시스템을 설치하고 있으며, 출입통제 절차를 수립‧운영</p></dd>

          <dt><p className='term-title'>11. 개인정보 보호책임자에 관한 사항</p></dt>
          <dd><p className='term-text'>① 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.</p></dd>
          <dd><p className='term-text'>② 개인정보 보호책임자 및 담당부서</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 성명 및 직책	: 김선욱 대표이사</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 전화번호	: 031-273-5050</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ Email :eulerlab@naver.com</p></dd>
          <dd><p className='term-text'>③ 정보주체는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의할 수 있습니다. 회사는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.</p></dd>
          <dd><p className='term-text'>④ 정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다.</p></dd>
         
          <dt><p className='term-title'>12. 영상정보처리기기 설치·운영</p></dt>
          <dd><p className='term-text'>① 회사는 아래와 같이 영상정보처리기기 설치 및 관리 등의 목적으로 위탁하고 있으며, 관계 법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다.</p></dd>
          <dd><p className='term-text'>② 영상정보처리기기 설치근거·목적 : 범죄예방, 시설안전, 화재예방, 학생 보호</p></dd>
          <dd><p className='term-text'>③ 설치 위치, 설치 대수 : 각 학원의 강의실, 복도, 데스크 등 주요시설물(각 학원별 상이, 각 학원의 별도 안내판 참조)</p></dd>
          <dd><p className='term-text'>④ 촬영 범위 : 주요시설물의 전 공간을 촬영</p></dd>
          <dd><p className='term-text'>⑤ 관리책임자, 담당부서 및 영상정보에 대한 접근 권한자 : 각 학원 및 독서실의 원장</p></dd>
          <dd><p className='term-text'>⑥ 영상정보 촬영시간, 보관기간, 보관장소, 처리방법</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 촬영 시간 : 24시간 촬영</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 보관 기간 : 촬영 시부터 90일</p></dd>
          <dd><p className='term-text'>⑦ 영상정보 확인 방법 및 장소 : 관리책임자에게 요구</p></dd>
          <dd><p className='term-text'>⑧ 정보주체의 영상정보 열람 등 요구에 대한 조치 : 개인영상정보 열람·존재 확인 청구서로 신청하여야 하며, 정보주체 자신이 촬영된 경우 또는 명백히 정보주체의 생명·신체·재산의 이익을 위해 필요한 경우에 한해 열람을 허용함</p></dd>
          <dd><p className='term-text'>⑨ 영상정보 보호를 위한 기술적·관리적·물리적 조치 : 내부관리계획 수립, 접근통제 및 접근권한 제한, 영상정보의 안전한 저장·전송기술 적용, 처리기록 보관 및 위·변조 방지조치, 보관시설 마련 및 잠금장치 설치</p></dd>

          <dt><p className='term-title'>13. 영상정보처리기기 설치 및 관리 등의 위탁에 관한 사항</p></dt>
          <dd><p className='term-text'>① 회사는 아래와 같이 영상정보처리기기 설치 및 관리 등을 위탁하고 있으며, 관계 법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다.</p></dd>

          <dt><p className='term-title'>14. 영상정보처리기기 운영·관리 방침의 변경에 관한 사항</p></dt>
          <dd><p className='term-text'>① 이 영상정보처리기기 운영·관리 방침은 2023년 5월 1일 제정되었으며, 법령·정책 또는 보안기술의 변경에 따라 내용의 추가·삭제 및 수정이 있을 시에는 본 기관 홈페이지를 통해 변경사유 및 내용을 공지하도록 하겠습니다.</p></dd>

          <dt><p className='term-title'>15. 개인정보 처리방침의 변경</p></dt>
          <dd><p className='term-text'>① 이 개인정보 처리방침은 2023년 5월 1일부터 적용됩니다.</p></dd>
          <dd><p className='term-text'>② 회사는 개인정보처리방침을 개정 또는 변경하는 경우 웹사이트 공지사항(또는 개별 공지)을 통하여 공지할 것입니다.</p></dd>
        </dl>
      </div>
    </section>
  )
}

export default SitePrivacy