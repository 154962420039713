import { useEffect, useRef } from 'react'
import { gsap } from 'gsap'
import { throttle } from 'lodash'
import './Badges.css'

export default function Badges() {
  const badgesRef = useRef()
  const toTopRef = useRef()
  
  // https://tech.kakaoenterprise.com/149
  const throttleFunc = throttle(()=>{
    if(window.scrollY > 500) {
      // badgeEl.style.display = 'none'
      gsap.context(() => {
        gsap.to(badgesRef.current , .6, {
          opacity: 0,
          display: 'none'
        })
      }, badgesRef)
      // ScrollTop 버튼 보이기
      gsap.context(() => {
        gsap.to(toTopRef.current , .5, {
          x: 0
        })
      }, toTopRef)        
    } else {
      // badgeEl.style.display = 'block'
      gsap.context(() => {
        gsap.to(badgesRef.current , .6, {
          opacity: 1,
          display: 'block'
        })
      }, badgesRef)
      // ScrollTop 버튼 숨기기
      gsap.context(() => {
        gsap.to(toTopRef.current , .5, {
          x: 100
        })
      }, toTopRef) 
    }
  }, 300)

  useEffect(()=>{
    window.addEventListener('scroll', throttleFunc)
    return () => {
      window.removeEventListener('scroll', throttleFunc)
    }
  },[])

  const toTop = function() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  return (
    <>
      <div className='badges' ref={ badgesRef }>
        <div className='badge'>
          <img src='/images/badge1.png' alt='Badge1' />
        </div>
        <div className='badge'>
          <img src='/images/badge2.png' alt='Badge2' />
        </div>
        <div className='badge'>
          <img src='/images/badge3.png' alt='Badge3' />
        </div>
        <div className='badge'>
          <img src='/images/badge4.png' alt='Badge4' />
        </div>
      </div>
      <div id="to-top" className="material-symbols-outlined" ref={ toTopRef } onClick={ toTop }>
        arrow_upward
      </div>
  </>
  )
}