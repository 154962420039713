import React, { useState } from 'react'
import './Notice.css'
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Autoplay } from "swiper";
import { Link } from 'react-router-dom';

export default function Notice() {
  const [isHideAward, setIsHideAward] = useState(true)
  
  function handleAwardBanner() {
    const awardEl = document.querySelector('.awards .inner')
    const awardToggleBtnEl = document.querySelector('.notice .awardToggleBtn')
    setIsHideAward(!isHideAward)
    if (isHideAward) {
      // 숨김처리
      awardEl.classList.add('hide')
      awardToggleBtnEl.classList.add('rotate')
    } else {
      // 보임처리
      awardEl.classList.remove('hide')
      awardToggleBtnEl.classList.remove('rotate')
    }
  }
  
  return (
    <section className="notice">
      <div className="inner">
        <div className="notice_left">
          <div className='notice_title'>
            <a href="https://blog.naver.com/PostList.naver?blogId=euleredu&from=postList&categoryNo=72" target="_blank">
              <h2>원장컬럼</h2>
              <span className="material-symbols-outlined">more_vert</span>
            </a>
          </div>
          <ul className='column'>
            <li className='column-list'>
              <a href="https://blog.naver.com/euleredu/223508798457/" target="_blank">
              <span>◆</span> <u>코딩으로 영재고, 카이스트 합격 비결 - 25년차 원장</u>
              </a>
            </li>
            <li className='column-list'>
              <a href="https://blog.naver.com/euleredu/223518637047/" target="_blank">
              <span>◆</span> <u>실패없는 학원 선택 비결 5가지 - 25년차 원장</u>
              </a>
            </li>
            <li className='column-list'>
              <a href="https://blog.naver.com/euleredu/223512987688/" target="_blank">
              <span>◆</span> <u>한국정보올림피아드(KOI) 2차대회 만점 2명</u>
              </a>
            </li>
            <li className='column-list'>
              <a href="https://blog.naver.com/euleredu/223506368416/" target="_blank">
              <span>◆</span> <u>국제정보올림피아드(IOI) 영재반 6명 전원 합격 비결</u></a>
            </li>
            <li className='column-list'>
              <a href="https://blog.naver.com/euleredu/223488333507/" target="_blank">
              <span>◆</span> <u>한국수학올림피아드(KMO) 입상을 축하합니다.</u></a>
            </li>
            <li className='column-list'>
              <a href="https://blog.naver.com/euleredu/223465760088/" target="_blank">
              <span>◆</span> <u>한국정보올림피아드(KOI) 입상을 축하합니다.</u></a>
            </li>
          </ul>
        </div>
        <div className='notice_middle'>
          <div className='notice_bar'>
            <div className="notice_bar_line"></div>
          </div>
          <div className='awardToggleBtn' onClick={ handleAwardBanner }>
            <span className="material-symbols-outlined">
              expand_circle_down
            </span>
            <div className='tooltiptext rotate'>접기</div>
            <div className='tooltiptext'>펼치기</div>
          </div>
        </div>
      </div> 
    </section>
  )
}
/*
function NoticeSwiper() {
  const swiperStyle = {
    height: '60px',
  }
  return ( 
    <Swiper
      modules={[Autoplay]}
      slidesPerView='2'
      direction='vertical'
      spaceBetween={0}
      style={swiperStyle}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      loop={true}
      // onSlideChange={() => console.log('slide change')}
      // onSwiper={(swiper) => console.log(swiper)}
    >
      <SwiperSlide className='swiper-slide'><Link to="#/" onClick={e => e.preventDefault()}>2023년 서울대 합격을 축하합니다.</Link></SwiperSlide>
      <SwiperSlide className='swiper-slide'><Link to="#/" onClick={e => e.preventDefault()}>2022년 영재고 합격을 축하합니다.</Link></SwiperSlide>
      <SwiperSlide className='swiper-slide'><Link to="#/" onClick={e => e.preventDefault()}>2022년 한국중학생화학대회(KMChC) 입상을 축하합니다.</Link></SwiperSlide>
      <SwiperSlide className='swiper-slide'><Link to="#/" onClick={e => e.preventDefault()}>2022년 한국주니어수학올림피아드(KJMO) 입상을 축하합니다.</Link></SwiperSlide>
    </Swiper>
  );
} */