import React from 'react'
import './Banner.css'

export default function Banner() {
  return (
    <section className="banner">
      <div className="inner">
        <div className="title_pc">
          <img src="./images/banner01_pc.jpg" alt="오일러학원 메인 배너" />
        </div>
        <div className="title_mo">
          <img src="./images/banner01_mo.jpg" alt="오일러학원 메인 배너" />
        </div>
        <Subscription />
      </div>
    </section>
  )
}

function Subscription() {
  return (
    <a href="https://naver.me/xKEC8M6w" target="_blank"><div className="btn">신청하기</div></a>
  )
}