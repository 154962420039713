import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Badges from './Badges'
import './Nav.css'

const Nav = () => {
  const {pathname} = useLocation()
  const navigate = useNavigate()

  useEffect(()=>{
    if(pathname === '/')
      navigate('/main')
  },[navigate])

  function handleMenuStarter() {
    const headerEl = document.querySelector('header')
    if (headerEl.classList.contains('menuing')) {
      headerEl.classList.remove('menuing')
      document.documentElement.classList.remove('fixed')
    } else {
      headerEl.classList.add('menuing')
      document.documentElement.classList.add('fixed')
    }
  }
  
  return (
    <header>
      <div className='inner'>
        <div className="menu-starter" onClick={ handleMenuStarter }>
          <div>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className='logo'>
          <a href="/">
            <img alt='오일러EDU' src='/images/euleredu_logo1.svg' onClick={()=>( window.location.href = '/')} ></img>
          </a>
        </div>
        <div className='menu'>
          <div className='item'>
            <div className='campus'>캠퍼스찾기</div>
            <ul className='sub_menu'>
              <li><a href="https://blog.naver.com/euleredu" target="_blank">영통캠퍼스</a></li>
            </ul>
          </div>
          <div className='item'>
            <div div className='channel'>오일러채널</div>
            <ul className='sub_menu'>
              <li><a href="https://blog.naver.com/euleredu/" target="_blank">오일러BLOG</a></li>
              <li><a href="https://talk.naver.com/W5VXGX/" target="_blank">오일러톡톡</a></li>
              <li><a href="https://www.youtube.com/@eulertv/" target="_blank">오일러TV</a></li>
              <li><a href="https://euleroj.io/" target="_blank">오일러OJ</a></li>
              <li><a href="https://smartstore.naver.com/eulerbooks/" target="_blank">오일러BOOKS</a></li>
              <li><a href="https://www.instagram.com/euler_lab/" target="_blank">오일러INSTA</a></li>
              <li><a href="https://band.us/@euler" target="_blank">오일러BAND</a></li>
            </ul>
          </div>
        </div>
        <div className='member'>
          <ul className='tooltip'>
            <li className='material-symbols-outlined'>credit_card</li>
            <li className='tooltiptext'>수강료납입</li>
          </ul>
          <span className='member_bar'></span>
          <ul className='tooltip'>
            <li className='material-symbols-outlined'>group</li>
            <li className='tooltiptext'>로그인</li>
          </ul>
        </div>
        <div className='clone-menu'>
          <h3>캠퍼스 찾기</h3>
          <ul>
            <li><a href="https://blog.naver.com/euleredu" target="_blank">영통캠퍼스</a></li>
          </ul>
          <h3>오일러채널</h3>
          <ul>
            <li><a href="https://blog.naver.com/euleredu/" target="_blank">오일러BLOG</a></li>
            <li><a href="https://talk.naver.com/W5VXGX/" target="_blank">오일러톡톡</a></li>
            <li><a href="https://www.youtube.com/@eulertv/" target="_blank">오일러TV</a></li>
            <li><a href="https://euleroj.io/" target="_blank">오일러OJ</a></li>
            <li><a href="https://smartstore.naver.com/eulerbooks/" target="_blank">오일러BOOKS</a></li>
            <li><a href="https://www.instagram.com/euler_lab/" target="_blank">오일러INSTA</a></li>
            <li><a href="https://band.us/@euler" target="_blank">오일러BAND</a></li>
          </ul>
          <h3>등록</h3>
          <ul>
            <li><a>수강료납입</a></li>
          </ul>
        </div>
      </div>
      <Badges />
    </header>
  )
}

export default Nav