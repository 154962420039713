import { useEffect } from 'react'
import { useLocation } from "react-router-dom"
import './SiteTerm.css'

const SiteTerm = () => {
  const { pathname } = useLocation()

  useEffect(()=>{
    window.scrollTo(0, 0)
  },[pathname])
  
  return (
    <section className='siteterm'>
      <div className="inner">
        <div className='header'>
          <img src='./images/siteterm_logo.png' alt='Logo' />
          <h1><strong>오일러 웹사이트 이용약관</strong></h1>
          <p>법적 정보 및 고지</p>
        </div>
        <dl className='term'>
          <dt><p className='term-title'>제1장 총칙</p></dt>
          
          <dt><p className='term-title'>제1조 [목적]</p></dt>
          <dd><p className='term-text'>이 약관은 오일러(주) (이하 "회사"라 합니다.)가 제공하는 인터넷서비스(이하 "서비스"라 하며, 접속 가능한 유ㆍ무선 단말기의 종류와는 상관없이 이용 가능한 "회사"가 온라인을 통해 제공하는 모든 "서비스"를 의미합니다.), 그 외 오일러에서 제작 및 제공되는 Mobile Application(이하 "모바일앱"이라 합니다)의 가입조건 및 이용에 관한 제반 사항과 기타 필요한 사항을 구체적으로 규정함을 목적으로 합니다.</p></dd>
          
          <dt><p className='term-title'>제2조 [약관의 효력과 변경]</p></dt>
          <dd><p className='term-text'>① 이 약관은 회원 가입함과 동시에 또는 전자우편, 기타의 방법으로 회원에게 통지함으로써 효력이 발생합니다.</p></dd>
          <dd><p className='term-text'>② 회사는 서비스 주요사항 변경, 사업 변경, 영업양수도, 경영환경의 변화 및 기타 사정 변경의 경우와 영업상 중요 사유가 있을 때 약관을 변경할 수 있으며, 변경된 약관은 전항과 같은 방법으로 효력이 발생합니다.</p></dd>
          <dd><p className='term-text'>③ 회원은 변경된 약관에 동의하지 않을 경우 회원 탈퇴(해지)를 요청할 수 있으며, 변경된 약관의 효력 발생일로부터 7일 이후에도 서비스를 계속 사용할 경우 약관의 변경사항에 동의한 것으로 간주됩니다.</p></dd>

          <dt><p className='term-title'>제3조 [약관 외 준칙]</p></dt>
          <dd><p className='term-text'>이 약관에 명시되지 않은 사항이 관계법령에 규정되어 있을 경우에는 그 규정에 따릅니다.</p></dd>

          <dt><p className='term-title'>제4조 [용어의 정의]</p></dt>
          <dd><p className='term-text'>① 회원 : 서비스를 제공받기 위해 회사와 이용계약을 체결하거나 이용자 아이디(ID)를 부여 받은 자 또는 그 회원전체를 말합니다.</p></dd>
          <dd><p className='term-text'>② 서비스 : 회사가 제공하는 온라인 상의 정보 제공 서비스를 의미합니다.</p></dd>
          <dd><p className='term-text'>③ 이용자 : 회사와 서비스 이용 계약을 체결한 실명의 개인 및 법인 또는 법인에 준하는 단체를 의미합니다.</p></dd>
          <dd><p className='term-text'>④ 아이디(ID) : 회원의 식별과 서비스를 이용하기 위해 회원이 정하고 회사가 승인하는 문자, 숫자 또는 양자의 조합을 말합니다.</p></dd>
          <dd><p className='term-text'>⑤ 비밀번호 : 회원이 정한 아이디(ID)와 일치된 회원임을 확인하고 회원 자신의 비밀을 유지하기 위하여 회원이 정한 문자, 숫자 또는 양자의 조합을 말합니다.</p></dd>
          <dd><p className='term-text'>⑥ 해지 : 회사와 회원이 서비스를 개통 후 이용계약을 종료시키는 의사표시를 말합니다.</p></dd>
          <dd><p className='term-text'>⑦ 게시물 등 : 회원이 서비스를 이용함에 있어 서비스상에 게시하거나 서비스를 통해 회사에 제출 또는 전송한 부호, 문자, 음성, 음향, 화상, 동영상 등의 정보 형태의 글, 사진, 동영상 및 각종 파일과 링크 등을 의미합니다.</p></dd>
          <dd><p className='term-text'>⑧ 이 약관에서 사용하는 용어 중 본 조에서 정하지 아니한 것은 관계 법령에서 정하는 바에 따르며, 그 외에는 일반 관례에 따릅니다.</p></dd>

          <dt><p className='term-title'>제2장 서비스 이용계약</p></dt>
          <dt><p className='term-title'>제5조 [이용 계약의 성립]</p></dt>
          <dd><p className='term-text'>① 서비스 가입 약관 하단의 ‘위의 내용에 동의하십니까·’라는 이용 신청시의 물음에 회원이 ‘동의’ 버튼을 클릭하면 이 약관에 동의하는 것으로 간주됩니다.</p></dd>
          <dd><p className='term-text'>② 이용 계약은 이용자의 이용 신청에 대한 회사의 이용 승낙과 이용자의 약관 내용에 대한 동의로 성립됩니다.</p></dd>
          <dd><p className='term-text'>③ 회원에 가입하여 서비스를 이용하고자 하는 희망자는 회사에서 요청하는 개인 정보를 제공해야 합니다.</p></dd>
          <dd><p className='term-text'>④ 회사는 다음 각 호에 해당하는 이용계약신청에 대하여는 이를 승낙하지 않습니다.</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 다른 사람의 명의를 사용하여 신청하였을 때</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 이용 계약 신청서의 내용을 허위로 기재하였거나 허위서류를 첨부하여 신청하였을 때</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 사회의 안녕 질서 혹은 미풍양속을 저해할 목적으로 신청하였을 때</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 기타 규정한 제반 사항을 위반하며 신청하는 경우</p></dd>
          <dd><p className='term-text'>⑤ 회원의 개인정보에 대해서는 회사의 개인정보 보호정책이 적용됩니다.</p></dd>
          <dd><p className='term-text'>⑥ 회사가 신청서에 기재를 요구하는 회원의 개인정보는 본 회원계약의 이행과 본 회원계약상의 서비스 제공을 위한 목적으로 이용됩니다.</p></dd>
          <dd><p className='term-text'>⑦ 회원이 회사 및 회사와 제휴한 서비스들을 편리하게 이용할 수 있도록 하기 위해 회원정보는 회사와 제휴한 업체에 제공될 수 있습니다. 단, 회사는 회원정보 제공 이전에 제휴업체, 제공 목적, 제공할 회원정보의 내용을 사전에 공지하고 회원의 동의를 얻어야 합니다.</p></dd>
          <dd><p className='term-text'>⑧ 회원은 온라인 사이트상 회원정보 페이지를 통해 언제든지 개인정보를 열람 및 수정을 할 수 있습니다.</p></dd>

          <dt><p className='term-title'>제6조 [결제 수단]</p></dt>
          <dd><p className='term-text'>① 회원이 유료 서비스 지불을 위하여 이용할 수 있는 결제 수단은 다음 각 호와 같으며, 각 결제 수단마다 결제가 가능한 유료 서비스가 제한 될 수 있습니다.</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 선불카드, 직불카드, 신용카드 등의 각종 카드결제</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 인터넷 뱅킹 등의 각종 계좌이체</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 간편결제</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 회사 또는 회사와 계약을 체결한 자가 제공하는 포인트(사이버머니, 마일리지 등 명칭불문)</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 기타 회사가 제공하는 전자적 지급방법에 의한 대금지급 등</p></dd>
          <dd><p className='term-text'>② 회사의 사정에 따라 특정 결제 수단이 추가되거나, 제공이 중단될 수 있습니다.</p></dd>
          <dd><p className='term-text'>③ 결제 수단과 함께 사용될 수 있는 포인트 결제의 경우, 회사의 사정에 따라 제공기간, 방식 및 사용취소 정책 등이 변경될 수 있습니다.</p></dd>

          <dt><p className='term-title'>제7조 [서비스 이용]</p></dt>
          <dd><p className='term-text'>① 서비스 이용은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간을 원칙으로 합니다.</p></dd>
          <dd><p className='term-text'>② 제1항의 이용시간은 정기점검 등의 필요로 인하여 회사가 정한 날 또는 시간은 그러하지 아니합니다.</p></dd>
          <dd><p className='term-text'>③ 회원 가입 이후라도 일부 서비스 이용 시 이용자의 연령에 따른 서비스 이용을 제한할 수 있습니다.</p></dd>

          <dt><p className='term-title'>제8조 [서비스 이용 요금]</p></dt>
          <dd><p className='term-text'>① 별도로 표시된 유료 서비스를 제외한 모든 서비스는 재원중인 학원생 학부모님에게 무료로 제공됩니다.</p></dd>
          <dd><p className='term-text'>② 유료 서비스의 이용 요금, 결제 방식 및 환불 규정은 본 약관에서 규정한 내용을 원칙으로 하며, 별도로 해당 서비스에 명시되어 있는 경우 그 규정을 따릅니다.</p></dd>

          <dt><p className='term-title'>제9조 [서비스 승인]</p></dt>
          <dd><p className='term-text'>① 이용자가 회사에서 규정한 결제방법을 사용하여 이용 요금을 납입한 사실이 확인되면 회사는 지체 없이 이용자의 서비스신청을 승인합니다.</p></dd>
          <dd><p className='term-text'>② 회사는 아래에 해당하는 경우 서비스 신청에 대한 승인을 제한할 수 있고, 그 사유가 해소될 때까지 승인을 유보할 수 있습니다.</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 유료 서비스의 이용 요금을 납입하지 않은 경우</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 유료 서비스 신청 금액 총액과 입금 금액 총액이 일치하지 않은 경우</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 기타 회사가 거래의 안전등 정당한 사유에 의하여 필요하다고 인정되는 경우</p></dd>

          <dt><p className='term-title'>제3장 책임</p></dt>
          <dt><p className='term-title'>제10조 [회사의 의무]</p></dt>
          <dd><p className='term-text'>① 회사는 특별한 사정이 없는 한 이용자가 신청한 서비스 제공 개시일에 서비스를 이용 할 수 있도록 합니다.</p></dd>
          <dd><p className='term-text'>② 회사는 이 약관에서 정한 바에 따라 계속적, 안정적으로 서비스를 제공할 의무가 있습니다.</p></dd>
          <dd><p className='term-text'>③ 회사는 이용자의 개인 정보를 본인의 승낙 없이 타인에게 누설, 배포, 전송 등을 할 수 없습니다. 다만, 『정보통신망 이용촉진 및 정보보호 등에 관한 법령』(이하”정보통신망법”)등 관계법령에 의하여 관계 국가기관 등의 요구가 있는 경우에는 그러하지 아니합니다.</p></dd>
          <dd><p className='term-text'>④ 회사는 이용자로부터 제기되는 의견이나 불만이 정당하다고 인정할 경우에는 즉시 처리하여야 합니다. 다만, 즉시 처리가 곤란한 경우에는 이용자에게 그 사유와 처리 일정을 통보하여야 합니다.</p></dd>

          <dt><p className='term-title'>제11조 [이용자의 의무]</p></dt>
          <dd><p className='term-text'>① 아이디와 비밀 번호에 관한 모든 관리의 책임은 이용자에게 있습니다.</p></dd>
          <dd><p className='term-text'>② 자신의 아이디가 부정하게 사용된 경우, 이용자는 반드시 회사에 그 사실을 통보해야 합니다.</p></dd>
          <dd><p className='term-text'>③ 이용자는 이 약관 및 관계법령에서 규정한 사항을 준수하여야 합니다.</p></dd>
          <dd><p className='term-text'>④ 이용자는 타 이용자의 개인정보를 본인 승낙 없이 타인에게 누설, 배포, 전송 등을 할 수 없습니다.</p></dd>

          <dt><p className='term-title'>제4장 계약 해지 및 서비스 이용제한</p></dt>
          <dt><p className='term-title'>제12조 [계약 해지 및 이용제한]</p></dt>
          <dd><p className='term-text'>① 이용자가 이용 계약을 해지 하고자 하는 때에는 이용자 본인이 직접 온라인을 통해 개인정보변경/탈퇴 메뉴를 선택하셔서 해지 신청을 하여야 합니다.</p></dd>
          <dd><p className='term-text'>② 회사는 이용자가 다음 사항에 해당하는 행위를 하였을 경우 사전 통지 없이 이용 계약을 해지 하거나 또는 기간을 정하여 서비스 이용을 중지할 수 있습니다.</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 공공 질서 및 미풍 양속에 반하는 경우</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 범죄적 행위에 관련되는 경우</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 이용자가 국익 또는 사회적 공익을 저해할 목적으로 서비스 이용을 계획 또는 실행할 경우</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 타인의 서비스 아이디 및 비밀 번호를 도용한 경우</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 타인의 명예를 손상시키거나 불이익을 주는 경우</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 같은 사용자가 다른 아이디로 이중 등록을 한 경우</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 서비스에 위해를 가하는 등 서비스의 건전한 이용을 저해하는 경우</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 기타 관련법령이나 회사가 정한 이용조건에 위배되는 경우</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 회원의 최종 로그인 시점으로부터 3년 이상이 지날 경우</p></dd>

          <dt><p className='term-title'>제13조 [유료 서비스 이용에 관한 해제∙해지]</p></dt>
          <dd><p className='term-text'>① 회원이 유료 서비스 이용 신청을 취소하고자 하는 경우에는 취소 사유를 명기하여 본인이 직접 이메일 또는 전화 등을 통하여 취소할 수 있습니다.</p></dd>
          <dd><p className='term-text'>② 유료 서비스의 환불 조건은 ‘학원의 설립ㆍ운영 및 과외교습에 관한 법률 시행령’의 ‘교습비등 반환기준’을 따릅니다.</p></dd>
          <dd><p className='term-text'>③ 회사는 회원이 유료 서비스 이용 해제∙해지(청약 철회) 신청을 확인 후 환불 금액이 있을 경우, 회원의 해제∙해지(청약 철회) 의사표시가 회사에 도달한 날로부터 5 영업일 이내에 결제 수단 별 사업자에게 대금의 청구 정지 내지 취소를 요청하고, 회원이 결제한 동일 결제 수단으로 환불함을 원칙으로 합니다. 단, 회사가 사전에 회원에게 공지한 경우 및 아래의 각 호의 경우 각 결제 수단별 환불 방법, 환불 가능 기간 등에 약간의 차이가 있을 수 있습니다.</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 신용카드 등 수납 확인이 필요한 결제 수단의 경우에는 수납 확인일로부터 5영업일 이내</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 회사와의 약정을 통하여 사전에 취소 가능 기한 등을 미리 정하여 둔 경우</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 회원이 유료 서비스 이용으로 이미 얻은 이익이 있거나 중도 해지의 경우</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 회원이 환불 처리에 필요한 정보 또는 자료를 회사에 즉시 제공하지 않는 경우</p></dd>
          <dd><p className='term-text'>④ 회사 또는 회사와 제휴를 맺은 자가 무상으로 제공한 포인트(사이버머니, 마일리지 등 명칭 불문)는 사용 후 사용 취소(환불) 되지 않습니다. 단, 별도 정책으로 운영될 경우 별도 웹페이지 또는 메일 등으로 공지합니다.</p></dd>

          <dt><p className='term-title'>제14조 [이용 제한의 해제 절차]</p></dt>
          <dd><p className='term-text'>① 회사는 제12조의 규정에 의하여 이용제한을 하고자 하는 경우에는 그 사유, 일시 및 기간을 정하여 서면 또는 전화 등의 방법에 의하여 해당 이용자 또는 대리인에게 통지합니다. 다만, 회사가 긴급하게 이용을 정지할 필요가 있다고 인정하는 경우에는 그러하지 아니합니다.</p></dd>
          <dd><p className='term-text'>② 제1항의 규정에 의하여 이용정지의 통지를 받은 이용자 또는 그 대리인은 그 이용정지 의 통지에 대하여 이의가 있을 때에는 이의신청을 할 수 있습니다.</p></dd>
          <dd><p className='term-text'>③ 회사는 제2항의 규정에 의한 이의신청에 대하여 그 확인을 위한 기간까지 이용정지를 일시 연기할 수 있으며, 그 결과를 이용자 또는 그 대리인에게 통지합니다.</p></dd>
          <dd><p className='term-text'>④ 회사는 이용정지 기간 중에 그 이용정지 사유가 해소된 것이 확인된 경우에는 이용 정지 조치를 즉시 해제합니다.</p></dd>

          <dt><p className='term-title'>제15조 [이용자의 게시물]</p></dt>
          <dd><p className='term-text'>회사는 이용자가 게시하거나 등록하는 게시물 등이 다음 각 사항에 해당된다고 판단되는 경우에 사전 통지 없이 삭제할 수 있습니다.</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 다른 이용자 또는 제3자를 비방하거나 중상 모략으로 명예를 손상시키는 내용인 경우</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 공공질서 및 미풍 양속에 위반되는 내용인 경우</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 범죄적 행위에 결부된다고 인정되는 내용일 경우</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 제3자의 저작권 등 기타 권리를 침해하는 내용인 경우</p></dd>
          <dd><p className='term-text'>&nbsp;&nbsp;&nbsp;▪ 기타 관계 법령이나 회사에서 정한 규정에 위배되는 경우</p></dd>

          <dt><p className='term-title'>제5장 계약변경</p></dt>
          <dt><p className='term-title'>제16조 [계약사항의 변경]</p></dt>
          <dd><p className='term-text'>이용자는 내용을 변경하거나, 서비스를 해지할 경우에는 전화나 서비스/브라우저를 통해서 이용계약을 변경/해지하여야 합니다.</p></dd>

          <dt><p className='term-title'>제6장 정보의 제공</p></dt>
          <dt><p className='term-title'>제17조 [정보의 제공]</p></dt>
          <dd><p className='term-text'>회사는 이용자가 서비스 이용 중 필요가 있다고 인정되는 다양한 정보에 대해서는 전자우편이나 서신우편 등의 방법으로 이용자에게 제공할 수 있습니다.</p></dd>

          <dt><p className='term-title'>제7장 저작권 및 기타사항</p></dt>
          <dt><p className='term-title'>제18조 [저작권]</p></dt>
          <dd><p className='term-text'>① 오일러에서 제공되는 모든 디지털 콘텐츠에 대한 저작권은 회사에 있습니다.</p></dd>
          <dd><p className='term-text'>② 이용자는 회사가 제공하는 서비스를 이용함으로써 얻은 정보 또는 디지털 콘텐츠 등 회사의 저작물을 회사의 사전 승낙 없이 복제, 전송, 공중 송신, 출판, 배포, 방송 기타 방법에 의하여 영리 목적으로 이용하거나 제3자에게 이용하도록 할 수 없습니다.</p></dd>
          <dd><p className='term-text'>③ 이용자가 작성한 게시물에 대한 모든 책임은 이를 게시한 이용자에게 있습니다.</p></dd>
          <dd><p className='term-text'>④ 회사는 서비스상에 게시하거나 회사에 제공한 게시물 등을 사용, 저장, 복제, 수정, 출판, 배포, 제3자 제공, 2차적 저작물(이용자의 오단 패턴 등의 분석을 통해 보다 향상된 교육서비스를 제공하기 위하여 이용자의 게시물 등을 활용하여 제작한 것) 제작을 할 수 있습니다.</p></dd>

          <dt><p className='term-title'>제19조 [손해배상]</p></dt>
          <dd><p className='term-text'>① 회사는 서비스 요금이 무료인 동안의 서비스 이용과 관련하여 이용자에게 발생한 어떠한 손해에 관하여도 책임을 지지 않습니다.</p></dd>
          <dd><p className='term-text'>② 이용자가 이 약관의 규정을 위반하여 회사 또는 제3자에게 손해가 발생하게 되는 경우, 이 약관을 위반한 이용자는 회사 및 제 3자에게 발생한 손해를 배상할 책임이 있습니다.</p></dd>

          <dt><p className='term-title'>제20조 [면책조항]</p></dt>
          <dd><p className='term-text'>① 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.</p></dd>
          <dd><p className='term-text'>② 회사는 이용자의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을 지지 않습니다.</p></dd>
          <dd><p className='term-text'>③ 회사는 이용자가 서비스를 이용하여 기대하는 수익을 상실한 것이나 서비스를 통하여 얻은 자료로 인한 손해에 관하여 책임을 지지 않습니다.</p></dd>
          <dd><p className='term-text'>④ 회사는 이용자가 서비스에 게재한 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 관하여는 책임을 지지 않습니다.</p></dd>
          <dd><p className='term-text'>⑤ 회사는 서비스 이용과 관련하여 가입자에게 발생한 손해 가운데 가입자의 고의, 과실에 의한 손해에 대하여 책임을 지지 않습니다.</p></dd>

          <dt><p className='term-title'>제21조 [관할법원]</p></dt>
          <dd><p className='term-text'>서비스 이용으로 발생한 분쟁에 대해 소송이 제기될 경우 회사의 본사 소재지를 관할하는 법원을 전속 관할법원으로 합니다.</p></dd>

          <dt><p className='term-title'>(부칙)</p></dt>
          <dd><p className='term-text'>(시행일) 이 약관은 2023년 05월 01일부터 시행합니다.</p></dd>
        </dl>
      </div>
    </section>
  )
}

export default SiteTerm