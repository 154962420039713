import Banner from '../components/Banner'
import Notice from '../components/Notice'
import Awards from '../components/Awards'
import Branch from '../components/Branch'
import EulerTv from '../components/EulerTv'

export default function MainPage() {
  return (
    <div className='mainpage'>
      <Banner />
      <Notice />
      <Awards />
      <Branch />
      <EulerTv />
    </div>
  )
}
