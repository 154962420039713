import { Link } from 'react-router-dom'
import './Footer.css'

export default function Footer() {
  
  return (
    <footer>
      <div className='inner'>
        <ul className="footer-upper">
          <li><div><Link to="/siteterm">이용약관</Link></div></li>
          <li style={{fontWeight: "700"}}><div><Link to="/siteprivacy">개인정보처리방침</Link></div></li>
          <li><div><Link to="/sitecollection">개인정보 무단수집 거부</Link></div></li>
        </ul>
        <div className="footer-lower">
          <ul>
            <li><div><span>주소</span> 경기도 수원시 영통구 1005-3 (4F,5F)</div></li>
            <li><div><span>회사</span> 오일러(주)</div></li>
            <li><div><span>대표</span> 김선욱</div></li>
            <li><div><span>전화</span> 031-273-5250</div></li>
            <li><div><span>사업자등록번호</span> 154-88-01338</div></li>
          </ul>
          <ul>
            <li><div><span>통신판매업신고</span> 제2020-수원영통-0482호</div></li>
            <li><div>Copyright(c) {new Date().getFullYear()} Euler INC. All Rights Reserved</div></li>
          </ul>
        </div>
      </div>
    </footer>
  )
}